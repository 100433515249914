import { createRouter, createWebHistory, RouteRecordRaw,createWebHashHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  {
    path: "/bgPage",
    name: "bgPage",
    component: () =>
      import(/* webpackChunkName: "bgPage" */ "../views/bgPage.vue"),
  },
  {
    path: "/bgLogin",
    name: "bgLogin",
    component: () =>
      import(/* webpackChunkName: "bgLogin" */ "../views/bgLogin.vue"),
  },
  {
    path: "/all",
    name: "all",
    component: () => import(/* webpackChunkName: "all" */ "../views/all.vue"),
    redirect: "index",
    children: [
      {
        path: "/index",
        name: "index",
        component: () =>
          import(/* webpackChunkName: "index" */ "../views/index.vue"),
      },
      {
        path: "/ai",
        name: "ai",
        component: () => import(/* webpackChunkName: "ai" */ "../views/ai.vue"),
      },
      {
        path: "/getcode",
        name: "getcode",
        component: () =>
          import(/* webpackChunkName: "getcode" */ "../views/getcode.vue"),
      },
      {
        path: "/apple",
        name: "apple",
        component: () =>
          import(/* webpackChunkName: "apple" */ "../views/apple.vue"),
      },
      {
        path: "/bigdata",
        name: "bigdata",
        component: () =>
          import(/* webpackChunkName: "bigdata" */ "../views/bigdata.vue"),
      },
      {
        path: "/TG",
        name: "TG",
        component: () => import(/* webpackChunkName: "TG" */ "../views/TG.vue"),
      },
      {
        path: "/bomb",
        name: "bomb",
        component: () =>
          import(/* webpackChunkName: "bomb" */ "../views/bomb.vue"),
      },
      {
        path: "/detailprice",
        name: "detailprice",
        component: () =>
          import(
            /* webpackChunkName: "detail-price" */ "../views/detail-price.vue"
          ),
      },
      {
        path: "/numbercheck",
        name: "numbercheck",
        component: () =>
          import(
            /* webpackChunkName: "number-check" */ "../views/number-check.vue"
          ),
      },
      {
        path: "/messagein",
        name: "messagein",
        component: () =>
          import(
            /* webpackChunkName: "message-in" */ "../views/message-in.vue"
          ),
      },
      {
        path: "/messageout",
        name: "messageout",
        component: () =>
          import(
            /* webpackChunkName: "message-out" */ "../views/message-out.vue"
          ),
      },
      {
        path: "/messagecolor",
        name: "messagecolor",
        component: () =>
          import(
            /* webpackChunkName: "message-color" */ "../views/message-color.vue"
          ),
      },
      {
        path: "/emailgroup",
        name: "emailgroup",
        component: () =>
          import(
            /* webpackChunkName: "email-group" */ "../views/email-group.vue"
          ),
      },
      {
        path: "/virtualphone",
        name: "virtualphone",
        component: () =>
          import(
            /* webpackChunkName: "virtual-phone" */ "../views/virtual-phone.vue"
          ),
      },
      {
        path: "/smartlink",
        name: "smartlink",
        component: () =>
          import(
            /* webpackChunkName: "smart-link" */ "../views/smart-link.vue"
          ),
      },
      {
        path: "/sendreceipt",
        name: "sendreceipt",
        component: () =>
          import(
            /* webpackChunkName: "send-receipt" */ "../views/send-receipt.vue"
          ),
      },
      {
        path: "/rechargelog",
        name: "rechargelog",
        component: () =>
          import(
            /* webpackChunkName: "recharge-log" */ "../views/recharge-log.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});


export default router;
