import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'//element-plus
import 'element-plus/dist/index.css'//element-plus
import zhCn from 'element-plus/es/locale/lang/zh-cn'//element-plus\

const app = createApp(App)
app.use(ElementPlus,{//element-plus
  locale:zhCn,
})
app.use(router)
app.mount('#app')